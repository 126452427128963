<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import _ from "lodash";

import axios from 'axios';
const moment = require('moment');
// import { TimeAgo } from "vue2-timeago";
// import { required } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
// import StatDashboardTicket from "@/components/widgets/stat-dashboard-ticket";
import Loading from 'vue-loading-overlay';
// import DatePicker from 'vue2-datepicker';
// import 'vue2-datepicker/index.css';

export default {
    page: {
        title: "Form Ticket Gangguan",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: { Layout, PageHeader, Loading },
    data() {
        return {
            title: "Detail Ticket",
            items: [
            ],
            idTicket: "",
            holderStage: null,
            perangkatAktif: [],
            perangkatAktifOption: [
                { text: ' Ont', value: 'ont' },
                { text: ' Kabel Power', value: 'adaptor' },
            ],
            perangkatAktifOptionInput: {
                ont: false,
                adaptor: false,
            },
            perangkatPasif: [],
            perangkatPasifOption: [
                { text: ' SOC/SC', value: 'sc' },
                { text: ' Kabel Fiber', value: 'fo' },

                { text: ' FAT', value: 'fat' },
                { text: ' Kabel Distribusi', value: 'kabelDistribusi' },
            ],
            perangkatPasifOptionInput: {
                sc: false,
                fo: false,
                fat: false,
                kabelDistribusi: false
            },

            statusOptic: [],
            statusOpticOption: [],
            statusJaringan: [],
            statusJaringanOption: [
                { text: ' TerIsolir', value: 'isolir' },
                { text: ' Terkoneksi', value: 'connected' },
                { text: ' Wifi', value: 'wifi' },
            ],
            statusJaringanOptionInput: {
                isolir: false,
                connected: false,
                wifi: false,
            },
            isLoading: false,
            fullPage: true,
            stateTroubleShoot: {
                oltRx: null,
                onuRx: null,
                signal: null,
                onuIP: null,
                upTime: null,
                status: null,
                result: null,
                checkingPayment: null,
                rating: null
            },
            dataPerbaikan: {
                ont: false,
                adaptor: false,
                sc: false,
                fo: false,
                fat: false,
                kabelDistribusi: false,
                terisolir: false,
                terkoneksi: false,
                wifi: false,
                IPAddres: null,
                nilaiOA: null,
                nilaiOB: null,
                keteranganTambahan: null,
                noTicket: null,
                DPont: null,
                Dpadaptor: null,
                DPsc: null,
                DPfo: null,
                DPfat: null,
                DPkabelDistribusi: null,
                DPketeranganTambahan: null,
            },

            userName: null,
            lenghtForUpdate: null,
            soFar: null,
            checkerProgressStage: null,
            checked: {
                ont: true,
                adaptor: true
            },

            selectedPetugas: [],
            selectedPetugasCount: 1,
            holderIdPetugas: null,
            optionsPetugas: null,
            holderUpdateAssigment: null,
            holderUpdateStatus: null,
            optionStatusUpdate: {
                open: "open",
                closed: "closed"
            },
            holderDataBackend: null,
            nextButton: false,
            nextButtonApperance: false,
            dataPerbaikanFound: false,
            simpanButtonApprance: false


        };
    },


    async mounted() {
        if (!_.isEmpty(this.$route.query)) {

            let id = JSON.parse(JSON.stringify(this.$route.query.id));

            let a = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket/tiketDetail?id=' + id)
            console.log("a.data", a.data.ticketDataPlusDetailMountly.dataTicketMountly[0]);
            this.idTicket = JSON.parse(JSON.stringify(a.data.ticketDataPlusDetailMountly.dataTicketMountly[0]));
            this.holderDataBackend = JSON.parse(JSON.stringify(a.data.ticketDataPlusDetailMountly.dataTicketMountly[0]));

            if (this.idTicket.stage.length >= 1) {
                this.holderStage = this.idTicket.stage[this.idTicket.stage.length - 1].stageData.code
                switch (this.holderStage) {
                    case "ticketSubmitted":
                        this.holderStage = "Ticket Dibuat";
                        break;
                    case "ticketOnProgress":
                        this.holderStage = "Ticket Dalam Pengerjaan";
                        break;
                    case "ticketProgressDone":
                        this.holderStage = "Ticket Pengerjaan Selesai";
                        break;
                    case "ticketDone":
                        this.holderStage = "Ticket Selesai";
                        break;
                }

            }
            this.dataPerbaikan.noTicket = this.idTicket.noTicket
            console.log("this.idTicket", this.idTicket)
            for (let index = 0; index < this.idTicket.stage.length; index++) {
                this.idTicket.stage[index].stageData.createdAt = moment(this.idTicket.stage[index].stageData.createdAt).format("DD-MM-YYYY HH:mm");
            }

            console.log("this.holderDataBackend", this.holderDataBackend);


            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'data-perbaikan-ticket?noTicket=' + this.idTicket.noTicket).then(response => {
                if (response.data.total === 0)
                    return;
                else {

                    this.dataPerbaikan = response.data.data[0]
                    this.perangkatAktifOptionInput.ont = response.data.data[0].ont,
                        this.perangkatAktifOptionInput.adaptor = response.data.data[0].adaptor

                    const perangkatAktifKeys = ['ont', 'adaptor'];
                    const perangkatPasifKeys = ['sc', 'fo', 'fat', 'kabelDistribusi'];

                    perangkatAktifKeys.forEach(key => {
                        if (response.data.data[0][key] === "false") {
                            this.perangkatAktifOptionInput[key] = false;
                        }
                        else{
                            this.perangkatAktifOptionInput[key] = true;
                        }
                    });

                    perangkatPasifKeys.forEach(key => {
                        if (response.data.data[0][key] === "false") {
                            this.perangkatPasifOptionInput[key] = false;
                        }
                        else{
                            this.perangkatPasifOptionInput[key] = true;
                        }
                    });
                   
                   
                    this.statusJaringanOptionInput.isolir = response.data.data[0].terisolir
                    this.statusJaringanOptionInput.connected = response.data.data[0].terkoneksi
                    this.statusJaringanOptionInput.wifi = response.data.data[0].wifi
                    this.holderUpdateAssigment = this.idTicket
                    this.dataPerbaikanFound = true
                    console.log("bang", this.dataPerbaikan);
                    console.log("perangkatAktifOptionInput", this.perangkatPasifOptionInput);
                }

            })
            let testString = "&ticketNo=" + this.idTicket.noTicket
            console.log("ticket", this.idTicket);

            if (this.idTicket.stage[this.idTicket.stage.length - 1].stageData.isReadStage == 0) {
                await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-notif-dashboard?markRead=true' + testString).then(response => {


                    return response
                })
            }
            if (this.idTicket.isRead == false) {


                await axios.get(process.env.VUE_APP_URL_FEATHERS + 'ticket-notif-dashboard?markRead=true' + testString).then(response => {
                    console.log("response", response);
                    return response

                })

            }



        }


        if (this.dataPerbaikanFound == false && this.idTicket.stage[this.idTicket.stage.length - 1].stageData.code === "ticketDone") {
            Swal.fire({
                icon: "warning",
                title: "Data Perbaikan Belum Terisi",
                text: "Silakan Isi Data Pada Field Data Perbaikan",

            });

        }

        const loggeduser = localStorage.getItem('user');
        const jsonUser = JSON.parse(loggeduser)
        this.userName = jsonUser.username

        this.userId = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'login?username=' + jsonUser.username)
            .then(response => {
                return response.data.data[0]._id
            })
        this.dataPerbaikan.noTicket = this.idTicket.noTicket






    },
    computed: {

    },
    watch: {
        'perangkatAktifOptionInput': {
            deep: true,
            handler(newVal) {
                //console.log("this.perangkatAktifOptionInput",newVal.ont);

                if (newVal.ont == true) {
                    this.dataPerbaikan.ont = newVal.ont
                }
                if (newVal.ont == false)
                    this.dataPerbaikan.ont = newVal.ont
                if (newVal.adaptor == false)
                    this.dataPerbaikan.adaptor = newVal.adaptor
                if (newVal.adaptor == true)
                    this.dataPerbaikan.adaptor = newVal.adaptor

                // console.log("newVal.ont",newVal.ont);




            }
        },
        // 'perangkatAktifOptionInput.ont': {
        //     deep: true,
        //     handler(newVal) {
        //         if(newVal){
        //             console.log("this.perangkatAktifOptionInput",newVal);
        //             if (newVal == true){
        //                 this.dataPerbaikan.ont= newVal
        //             }
        //             if(newVal.ont == false)
        //             this.dataPerbaikan.ont= newVal.ont
        //             if(newVal.adaptor == false)
        //             this.dataPerbaikan.adaptor= newVal.adaptor
        //             if(newVal.adaptor == true)
        //             this.dataPerbaikan.adaptor= newVal.adaptor

        //             console.log("newVal.ont",newVal.ont);
        //         }


        //     }
        // },

        'selectedPetugas': {
            deep: true,
            handler(value) {
                // Pastikan this.holderSelectedIdPetugas adalah array yang sesuai
                if (!Array.isArray(this.holderSelectedIdPetugas)) {
                    this.holderSelectedIdPetugas = [];
                }

                for (let index = 0; index < value.length; index++) {
                    for (let index2 = 0; index2 < this.optionsPetugas.length; index2++) {
                        if (this.optionsPetugas[index2] === value[index]) {
                            // Pastikan array holderSelectedIdPetugas memiliki ukuran yang sesuai
                            this.holderSelectedIdPetugas[index] = this.holderIdPetugas[index2];
                        }
                    }
                }



            }
        },

        'perangkatPasifOptionInput': {
            deep: true,
            handler(newVal) {
                console.log('asd', newVal)
                this.dataPerbaikan.sc = newVal.sc ?? this.dataPerbaikan.sc;
                this.dataPerbaikan.fo = newVal.fo ?? this.dataPerbaikan.fo;
                this.dataPerbaikan.fat = newVal.fat ?? this.dataPerbaikan.fat;
                this.dataPerbaikan.kabelDistribusi = newVal.kabelDistribusi ?? this.dataPerbaikan.kabelDistribusi;


            }
        },
        'statusJaringanOptionInput': {
            deep: true,
            handler(newVal) {
                console.log("newVal", newVal);
                this.dataPerbaikan.terisolir = newVal.isolir ?? this.dataPerbaikan.terisolir;
                this.dataPerbaikan.terkoneksi = newVal.connected ?? this.dataPerbaikan.terkoneksi;
                this.dataPerbaikan.wifi = newVal.wifi ?? this.dataPerbaikan.wifi;

            }
        },
        'dataPerbaikan': {
            deep: true,
            handler(newValue) {

                if (newValue.adaptor != false || newValue.fat != false || newValue.fo != false || newValue.kabelDistribusi != false
                    || newValue.ont != false || newValue.sc != false || newValue.ont != false) {
                    this.nextButtonApperance = true
                }
                else {
                    this.nextButtonApperance = false
                }

                if (newValue.terisolir != false || newValue.terkoneksi != false || newValue.wifi != false && newValue.keteranganTambahan !== "" && newValue.adaptor != false && newValue.fat != false && newValue.fo != false && newValue.kabelDistribusi != false
                    && newValue.ont != false && newValue.sc != false && newValue.terisolir != false &&
                    newValue.terkoneksi != false && newValue.wifi != false && newValue.ont != false) {
                    console.log("preketek");
                    this.simpanButtonApprance = true

                }
                else {
                    this.simpanButtonApprance = false
                }

            }

        },

        'holderUpdateStatus': {
            deep: true,
            handler() {

                if (this.idTicket.stage[this.idTicket.stage.length - 1].stageData.code !== "ticketProgressDone") {
                    console.log("banggg");
                    Swal.fire({
                        icon: "error",
                        title: "Tiket Masih Dalam Pengerjaan",
                        text: "Silakan konfirmasi lagi dengan petugas",

                    });
                    this.holderUpdateStatus = null
                    this.$refs['modal-update-ticket'].hide()
                }
                else if (this.idTicket.stage[this.idTicket.stage.length - 1].stageDetail == null && this.idTicket.stage[this.idTicket.stage.length - 1].stageData.code === "ticketProgressDone") {
                    Swal.fire({
                        icon: "error",
                        title: "Hasil Pengerjaan Tiket Belum Di Cek ",
                        text: "Silakan Lakukan Pengecekan Terlebih Dahulu",

                    });
                    this.holderUpdateStatus = null
                    this.$refs['modal-update-ticket'].hide()

                }

                console.log(this.idTicket.stage[this.idTicket.stage.length - 1].stageDetail);


            }
        }
    },




    methods: {
        formattedDate(index, date) {

            console.log("dataaa", date);
            for (let index1 = index; index1 < this.idTicket.stage.length; index1++) {
                const element = this.idTicket.stage[index1];

                if (element.stageData.code === "ticketProgressDone") {
                    this.checkerProgressStage = true
                    let endTimeStr = this.idTicket.stage[index].stageData.createdAt;
                    let startTimeStr = this.idTicket.stage[index1].stageData.createdAt;

                    let endTime1 = moment(endTimeStr, "DD-MM-YYYY HH:mm");
                    let startTime1 = moment(startTimeStr, "DD-MM-YYYY HH:mm");
                    let duration = moment.duration(startTime1.diff(endTime1));

                    if (duration._data.hours >= 1) {
                        return "Waktu Pengerjaan " + duration._data.hours + " Jam " + duration._data.minutes + " Menit";
                    } else {
                        return "Waktu Pengerjaan " + duration._data.minutes + " Menit";
                    }

                }
                else if (!_.has(this.idTicket.stage[index1 + 1], "stageData")) {

                    let startTimeStr = this.idTicket.stage[index1].stageData.createdAt;
                    this.checkerProgressStage = false
                    let endTime1 = moment();
                    let startTime1 = moment(startTimeStr, "DD-MM-YYYY HH:mm");
                    let duration = moment.duration(endTime1.diff(startTime1));

                    if (duration._data.hours >= 1) {
                        return "" + duration._data.hours + " Jam " + duration._data.minutes + " Menit Yang Lalu";
                    } else if (duration._data.minutes >= 1) {
                        return duration._data.minutes + " Menit Yang Lalu";
                    }
                    else if (duration._data.minutes >= 59) {
                        return "Baru Saja"
                    }
                }


            }
            return '';



        },

        async simpanDataPerbaikan() {
            const url = process.env.VUE_APP_URL_FEATHERS + 'data-perbaikan-ticket';
            const noTicketUrl = `${url}?noTicket=${this.dataPerbaikan.noTicket}`;


            Swal.fire({
                title: "Simpan Data Perbaikan Ticket",

                showCancelButton: true,
                confirmButtonText: "Ya,Simpan",
            }).then(async (result) => {



                try {
                    const response = await axios.get(noTicketUrl);

                    if (response.data.total === 0) {

                        const responsePost = await axios.post(url, this.dataPerbaikan);
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: "Tersimpan!",
                                text: "Data Perbaikan Ticket Berhasil Di Simpan",
                                icon: "success"
                            });
                        }
                        this.$router.push({ path: '/ticket' })
                        return responsePost;
                    } else {
                        console.log("response,", response);
                        const patchUrl = `${url}/${response.data.data[0]._id}`;
                        const responsePost = await axios.patch(patchUrl, this.dataPerbaikan);
                        if (result.isConfirmed) {
                            Swal.fire({
                                title: "Tersimpan!",
                                text: "Data Perbaikan Ticket Berhasil Di Simpan",
                                icon: "success"
                            });
                        }
                        this.$router.push({ path: '/ticket' })
                        return responsePost;
                    }



                } catch (error) {
                    console.error("Error:", error);
                    throw error;
                }
            })




        },

        dataPenggunaanPerangkat() {

            this.nextButton = true
        },


        async checkTroubleShoot(row, index) {
            let noTicket = this.idTicket.orderId
            this.isLoading = true
            return await axios.get(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot?troubleshot=' + noTicket).then(response => {
                this.stateTroubleShoot = {
                    oltRx: response.data.oltRx,
                    onuRx: response.data.onuRx,
                    onuSignal: response.data.onuSignal,
                    onuIP: response.data.onuIP,
                    onuUptime: response.data.onuUptime,
                    onuState: response.data.onuState,
                    result: response.data.result,
                    checkingPayment: response.data.checkingPayment,
                    rating: response.data.rating,
                    ticketId: this.idTicket.noTicket,
                    stage_id: this.holderDataBackend.stage[index].stageData.createdAt,
                    code: row.stageData.code
                }
                // let arrayData= {
                //         stageData:{
                //             code: row.stageData.code,
                //             createdAt: row.stageData.code.createdAt,
                //             createdBy: row.stageData.code.createdBy,
                //         },
                //         stageDetail:{
                //             oltRx: response.data.oltRx,
                //             onuRx: response.data.onuRx,
                //             onuSignal: response.data.onuSignal,
                //             onuIP: response.data.onuIP,
                //             onuUptime: response.data.onuUptime,
                //             onuState: response.data.onuState,
                //             result: response.data.result,
                //             checkingPayment: response.data.checkingPayment,
                //             rating: response.data.rating,
                //             ticketId: this.idTicket.noTicket,
                //             stage_id: this.holderDataBackend.stage[index].stageData.createdAt,
                //             code: row.stageData.code
                //         }
                //     }
                this.idTicket.stage[index].stageDetail = this.stateTroubleShoot
            }).then(async () => {
                return await axios.post(process.env.VUE_APP_URL_FEATHERS + 'stage-detail-troubleshot', this.stateTroubleShoot).then(() => {
                    this.isLoading = false
                    this.lenghtForUpdate = this.idTicket.stage.length
                })

            })

        },
        async rollBackUpdate() {
            Swal.fire({
                title: "Update Stage Ticket",
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Ticket Selesai",
                denyButtonText: `Pengerjaan Ulang`
            }).then(async (result) => {

                if (result.isConfirmed) {
                    this.idTicket.status = "closed"
                    Swal.fire("Ticket Selesai!", "", "success");

                    let array = {
                        code: "ticketDone",
                        createdAt: new Date(),
                        createdBy: this.userName
                    }
                    this.idTicket.stage.push(array)



                    await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.idTicket.id, array).then(async response => {

                        this.$router.push({ path: '/ticket' })
                        return response
                    })

                } else if (result.isDenied) {
                    let date = moment().format('DD-MM-YYYY HH:MM')

                    let array = {
                        stageData: {
                            code: "ticketOnProgress",
                            createdAt: date,
                            createdBy: this.userName,
                        },
                        stageDetail: null
                    }


                    let arrayPatch = array.stageData
                    arrayPatch.createdAt = new Date()



                    this.idTicket.stage.push(array)
                    await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.idTicket.id, arrayPatch).then(async response => {

                        this.$router.push({ path: '/ticket' })
                        return response
                    })

                    Swal.fire("Ticket Dalam Pengerjaan Ulang", "", "info");
                }
            });



        },
        checkUpdateButton(index) {

            return index + 1 === this.idTicket.stage.length;
        },
        async updateWarningSetting() {
            await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
                this.holderIdPetugas = response.data.idPetugas
                return this.optionsPetugas = response.data.dataPetugas
            })
            this.$refs['modal-update-petugas'].show()

        },
        updateAssigementSeleceted(index) {
            this.holderSelectedIdPetugas.splice(index, 1)
            this.selectedPetugas.splice(index, 1)
            this.selectedPetugasCount--
        },
        async updatePatchAssigemnt() {
            let data = {
                petugas: this.holderSelectedIdPetugas
            }
            await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.holderUpdateAssigment.id, data).then(async response => {
                this.$refs['modal-update-petugas'].hide()
                return response
            })
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        async showModalUpdateTiket() {
            this.selectedPetugas = [],
                this.selectedPetugasCount = 1,
                this.holderIdPetugas = null,
                this.optionsPetugas = null,
                this.holderUpdateAssigment = null,



                await axios.get(process.env.VUE_APP_URL_FEATHERS + 'analaytic-dashboard-ticket?petugas=true').then(response => {
                    console.log("array petugas", response);
                    this.holderIdPetugas = response.data.idPetugas
                    return this.optionsPetugas = response.data.dataPetugas
                })
            this.$refs['modal-update-ticket'].show()
        },

        async updateTicketOnDetailPage() {

            if (this.selectedPetugas.length !== 0) {

                let data = {
                    petugas: this.holderSelectedIdPetugas
                }
                await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.idTicket.id, data).then(async response => {
                    this.$refs['modal-update-ticket'].hide()
                    this.$router.push({ path: '/ticket' })
                    console.log(response);
                    return response
                })
            }

            else if (this.holderUpdateStatus !== null) {


                let array = {
                    code: "ticketDone",
                    createdAt: new Date(),
                    createdBy: this.userName
                }
                console.log(array);
                this.idTicket.stage.push(array)



                await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'ticket/' + this.idTicket.id, array).then(async response => {
                    this.$refs['modal-update-ticket'].hide()
                    this.$router.push({ path: '/ticket' })
                    return response
                })
            }

            return this.$refs['modal-update-ticket'].hide()

        },



    },


    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <loading :active="isLoading"
                 :is-full-page="fullPage"
                 color="#ff0000" />
        <div class="row">
            <div class="col-6">
                <b-card title="Informasi Ticket" :sub-title="idTicket.noTicket">

                    <div class="row">
                        <div class="col-md-6">

                            <div class="mb-3">
                                <label class="form-label" for="validationCustom01">Nama Pelanggan</label>
                                <input type="text" class="form-control" id="validationCustom01" placeholder="First name"
                                    :value="idTicket.customerName" disabled>
                                <div class="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="validationCustom02">Order Id</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Last name"
                                    required="" :value="idTicket.orderId" disabled>
                                <div class="valid-feedback">
                                    Looks good!
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="validationCustom04">Prioritas</label>
                                <input type="text" class="form-control" id="validationCustom04" placeholder="State"
                                    required="" :value="idTicket.priority" disabled>
                                <div class="invalid-feedback">
                                    Please provide a valid state.
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="validationCustom04">Petugas</label>
                                <div v-if="idTicket.assignmentGroup.length != 0">
                                    <input type="text" class="form-control" id="validationCustom04" placeholder="State"
                                        required="" :value="idTicket.assignmentGroup" disabled>
                                </div>
                                <div v-if="idTicket.assignmentGroup.length == 0">
                                    <b-input-group>
                                        <b-form-input disabled
                                            placeholder="Belum Ada Petugas Yang DiTugaskan"></b-form-input>
                                        <b-input-group-append>
                                            <b-button variant="warning" @click="updateWarningSetting()">Set
                                                Petugas</b-button>
                                        </b-input-group-append>
                                    </b-input-group>

                                </div>
                                <div class="invalid-feedback">
                                    Please provide a valid state.
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="validationCustom05">Keterangan</label>
                                <div >
                                    <b-form-textarea placeholder="Silahkan Jabarkan Detail Kendala" disabled
                                :value="idTicket.problems"></b-form-textarea>
                                </div>
                            </div>


                        </div>
                        <div class="col-md-6">

                            <div class="mb-3">
                                <label class="form-label" for="validationCustom02">Status Ticket</label>
                                <input type="text" class="form-control" id="validationCustom02" placeholder="Last name"
                                    required="" :value="idTicket.status" disabled>

                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="validationCustom03">Kategori</label>
                                <input type="text" class="form-control" id="validationCustom03" placeholder="City"
                                    required="" :value="idTicket.category" disabled>
                                <div class="invalid-feedback">
                                    Please provide a valid city.
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label" for="validationCustom05">Status Pengerjaan</label>
                                <input type="text" class="form-control" id="validationCustom05" placeholder="Zip"
                                    required="" :value="holderStage" disabled>
                                <div class="invalid-feedback">
                                    Please provide a valid zip.
                                </div>
                            </div>
                            <div class="mb-3">
                                <label class="form-label" for="validationCustom04">Dibuat Pada</label>
                                <input type="text" class="form-control" id="validationCustom04" placeholder="State"
                                    required="" :value="idTicket.createdAt" disabled>
                                <div class="invalid-feedback">
                                    Please provide a valid state.
                                </div>
                            </div>
                            <div class="mb-3 float-end" v-if="idTicket.status !== 'closed'">
                                <b-button class="mt-md-5 " @click="showModalUpdateTiket">Update Tiket</b-button>
                                </div>
                        </div>
                        
                    </div>
                    
                </b-card>
                <b-card title="Data Perbaikan" sub-title="Rekap Perbaikan Petugas">
                    <div class="row">

                        <div class="col-md-6">
                            <div class="mb-1">
                                <label class="form-label mb-1" for="validationCustom01"
                                    style="color:rgb(114, 43, 43);">&nbsp;Perangkat Aktif</label>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="perangkatAktifOptionInput.ont"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;Ont</label>
                                    </div>
                                </b-form-group>



                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="perangkatAktifOptionInput.adaptor"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;Kabel
                                            Power</label>
                                    </div>
                                </b-form-group>


                            </div>
                            <div class="mb-2">
                                <label class="form-label mt-1 mb-1" for="validationCustom01"
                                    style="color:rgb(114, 43, 43);">&nbsp;Perangkat Pasif</label>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="perangkatPasifOptionInput.sc"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;SC</label>
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="perangkatPasifOptionInput.fo"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;FO</label>
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="perangkatPasifOptionInput.fat"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;FAT</label>
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" class="mb-2"
                                            v-model="perangkatPasifOptionInput.kabelDistribusi"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;Kabel
                                            Distribusi</label>
                                    </div>
                                </b-form-group>

                            </div>

                        </div>

                        <div class="col-md-6">

                            <div class="mb-3">
                                <label class="form-label mb-1 mt-1" for="validationCustom01"
                                    style="color:rgb(114, 43, 43);">Status Jaringan</label>

                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="statusJaringanOptionInput.isolir"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;Isolir</label>
                                    </div>
                                </b-form-group>
                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="statusJaringanOptionInput.connected"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0"
                                            for="validationCustom01">&nbsp;Terkoneksi</label>
                                    </div>
                                </b-form-group>

                                <b-form-group>
                                    <div class="d-flex align-items-center" size="sm">
                                        <b-form-checkbox id="checkbox-group-1" v-model="statusJaringanOptionInput.wifi"
                                            class="mb-2"></b-form-checkbox>
                                        <label class="form-label pr-2 mt-0" for="validationCustom01">&nbsp;Wifi</label>
                                    </div>
                                </b-form-group>
                                <div class="row">
                                    <div class="col-md-3 mt-1 form-label" for="validationCustom01"><label class="mr-2">IP
                                            Address</label></div>
                                    <div class="col-md-6 mb-1 pl-3" style="padding-left: 2.938rem;">
                                        <b-form-input placeholder="IP Address" v-model="dataPerbaikan.IPAddres"
                                            size="sm"></b-form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 mb-1">
                                <label class="form-label mt-1 mb-1" for="validationCustom01"
                                    style="color:rgb(114, 43, 43);">Status Optic</label>
                                <div class="row">
                                    <div class="col-md-4 mb-4 mr-3">
                                        <label class="mr-1 mt-1">Nilai Optic Awal</label>
                                        <label class="mr-1">Nilai Optic Baru</label>
                                    </div>
                                    <div class="col-md-5 mb-4" style="padding-left: 0.813rem;">
                                        <b-form-group>
                                            <b-form-input placeholder="Nilai Optic Awal" size="sm"
                                                v-model="dataPerbaikan.nilaiOA"></b-form-input>
                                            <b-form-input class="mt-1" placeholder="Nilai Optic Baru"
                                                v-model="dataPerbaikan.nilaiOB" size="sm"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>


                            </div>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <b-form-textarea placeholder="Silahkan Jabarkan Detail Kendala"
                                v-model="dataPerbaikan.keteranganTambahan"></b-form-textarea>
                        </div>
                        <div class="col-md-2" v-if="nextButtonApperance && dataPerbaikanFound === false">
                            <b-button class="mt-3 float-end" style="width:100%"
                                @click="dataPenggunaanPerangkat()">Next</b-button>
                        </div>

                        <div class="col-md-2"
                            v-if="simpanButtonApprance == true && dataPerbaikanFound === false && !nextButtonApperance">
                            <b-button class="mt-3 float-end" style="width:100%"
                                @click="simpanDataPerbaikan()">Simpan</b-button>
                        </div>
                    </div>


                </b-card>
                <b-card title="Data Penggunaan Perangkat" sub-title="Rekap Penggunaan Perangkat"
                    v-show="nextButton || dataPerbaikanFound">
                    <div class="row">
                        <div class="col-lg-5">

                            <div class="mb-3">
                                <label class="form-label" for="validationCustom01"
                                    v-if="perangkatAktifOptionInput.ont != false && perangkatAktifOptionInput.adaptor != false && perangkatAktifOptionInput.ont !== 'false' && perangkatAktifOptionInput.adaptor !== 'false'">Perangkat
                                    Aktif</label>

                                <div class="input-group"
                                    v-if="perangkatAktifOptionInput.ont != false && perangkatAktifOptionInput.ont !== 'false'">
                                    <div class="input-group-text">Ont
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <input type="number" class="form-control" size="md" style=" padding-left: 2px;width:50%"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.DPont"
                                        v-mask="'#########'" placeholder="Jumlah Ont" />
                                </div>

                                <div class="input-group mt-2"
                                    v-if="perangkatAktifOptionInput.adaptor != false && perangkatAktifOptionInput.adaptor !== 'false'">
                                    <div class="input-group-text">Kabel Power &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <input type="number" class="form-control" style=" padding-left: 2px;"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.Dpadaptor"
                                        v-mask="'#########'" placeholder="Panjang Kabel Power" />
                                </div>
                            </div>

                            <div class="mb-3">
                                <label class="form-label font-size-15" for="validationCustom01"
                                    v-if="Object.values(perangkatPasifOptionInput).some(option => option)">
                                    Perangkat Pasif
                                </label>


                                <div class="input-group "
                                    v-if="perangkatPasifOptionInput.sc !== false && perangkatPasifOptionInput.sc !== 'false'">
                                    <div class="input-group-text">SOC
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <input type="number" class="form-control" style=" padding-left: 2px;"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.DPsc"
                                        v-mask="'#########'" placeholder="Jumlah SOC" />
                                </div>
                                <div class="input-group mt-2"
                                    v-if="perangkatPasifOptionInput.fo !== false && perangkatPasifOptionInput.fo !== 'false'">
                                    <div class="input-group-text">Fiber Optic
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <input type="number" class="form-control" style=" padding-left: 2px;"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.DPfo"
                                        v-mask="'#########'" placeholder="Panjang Fiber Optic" />
                                </div>


                                <div class="input-group mt-2"
                                    v-if="perangkatPasifOptionInput.fat !== false && perangkatPasifOptionInput.fat !== 'false'">
                                    <div class="input-group-text">FAT
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <input type="number" class="form-control" style=" padding-left: 2px;"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.DPfat"
                                        v-mask="'#########'" placeholder="Jumlah Fat" />
                                </div>

                                <div class="input-group mt-2"
                                    v-if="perangkatPasifOptionInput.kabelDistribusi !== false && perangkatPasifOptionInput.kabelDistribusi !== 'false'">
                                    <div class="input-group-text">Kabel Distribusi &nbsp;

                                    </div>
                                    <input type="number" class="form-control" style=" padding-left: 2px;"
                                        id="specificSizeInputGroupUsername" v-model="dataPerbaikan.DPkabelDistribusi"
                                        v-mask="'#########'" placeholder="Panjang Kabel Distribusi  " />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="mb-3">
                                <p class="mt-4"></p>

                            </div>




                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <b-form-textarea placeholder="Tambahkan Keterangan "
                                v-model="dataPerbaikan.DPketeranganTambahan"></b-form-textarea>
                        </div>
                        <div class="col-md-2"
                            v-if="Object.values(perangkatPasifOptionInput).some(option => option) || Object.values(perangkatAktifOptionInput).some(option => option)">
                            <b-button class="mt-3 float-end" size="md" @click="simpanDataPerbaikan()">Simpan</b-button>
                        </div>
                    </div>

                </b-card>
            </div>
            <div class="col-6">
                <b-card title="Riwayat Pengerjaan Tiket" sub-title="Detail Pengerjaan Ticket Per Status Pengerjaan"
                    style="min-height: 64.375rem;" :style="{ maxHeight: '88.918rem', overflowY: 'auto' }">
                    <ol class="activity-feed mb-0 ps-1" data-simplebar>


                        <li class="feed-item pb-3" style="border-left: 2px solid #0f55e0;"
                            v-for="(stage, index) in idTicket.stage" :key="stage.stageData._id">

                            <div class="mt-4 mt-xl-3 ps-xl-4" v-if="stage.stageData.code == 'ticketSubmitted'">
                                <h5 class="font-size-14"><a href="#" class="text-muted">{{ stage.stageData.createdAt }} ,
                                        <span class="badge bg-info-subtle text-info">{{ stage.stageData.createdBy
                                        }}</span></a>
                                    <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                                        v-if="stage.stageData.isReadStage == 0"><i class="font-size-13">Baru</i></span>
                                </h5>

                                <h4 class="font-size-18 mb-3" v-if="stage.stageData.code === 'ticketSubmitted'">Tiket Dibuat
                                </h4>

                                <div class="text-muted" v-if="stage.stageDetail != null">
                                    <span class="badge bg-info font-size-14 me-1"><i class="mdi mdi-star"></i>
                                        {{ stage.stageDetail.rating }}
                                        Awal</span>

                                    <span v-for="result in stage.stageDetail.result" :key="result._id"><span
                                            :class="result === 'Tidak Ada Kendala' ? 'badge bg-success-subtle text-success' : 'badge bg-danger-subtle text-danger'">{{
                                                result }}</span></span>
                                </div>

                                <div v-if="stage.stageDetail != null">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <div class="row">
                                                    <h5 class="font-size-14">Rekap Jaringan :</h5>
                                                    <div class="col-md-5">
                                                        <ul class="list-unstyled product-desc-list text-muted">
                                                            <li class="mb-1">
                                                                <i
                                                                    class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                oltRx
                                                            </li>
                                                            <li class="mb-1"><i
                                                                    class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                onuRx</li>
                                                            <li class="mb-1"><i
                                                                    class="uil uil-clock-three text-primary me-1 font-size-16"></i>
                                                                onuUptime
                                                            </li>
                                                            <li class="mb-1"><i
                                                                    class="uil uil-wifi-router text-primary me-1 font-size-16"></i>
                                                                onuIP
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.oltRx"
                                                            disabled></b-input>
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuRx"
                                                            disabled></b-input>
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuUptime"
                                                            disabled></b-input>
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuIP"
                                                            disabled></b-input>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="mt-3">
                                                <h5 class="font-size-14">Status Jaringan :</h5>
                                                <div class="row">
                                                    <div class="col-md-5">
                                                        <ul class="list-unstyled product-desc-list text-muted">
                                                            <li><i
                                                                    class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                onuState</li>
                                                            <li><i
                                                                    class="uil uil-signal text-primary me-1 font-size-16"></i>
                                                                onuSignal</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuState"
                                                            disabled></b-input>
                                                        <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuSignal"
                                                            disabled></b-input>
                                                    </div>
                                                    <div class="col-md-5">
                                                        <h5 class="font-size-14">Tagihan Bulan Ini :</h5>
                                                        <ul class="list-unstyled product-desc-list text-muted pt-1">
                                                            <li><i class="uil uil-bill text-primary me-1 font-size-16"></i>
                                                                Status</li>
                                                        </ul>
                                                    </div>
                                                    <div class="col-md-5 mt-4"><b-input class="mt-1" size="sm"
                                                            :value="stage.stageDetail.checkingPayment" disabled></b-input>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div
                                    v-if="stage.stageData.code === 'ticketSubmitted' && idTicket.category !== 'installation' && idTicket.isCustomer == false" >
                                    <div class="row">

                                        <div class="col-md-1">
                                            <div class="mt-3">
                                                <div class="row">
                                                    <h1><span class="bx bxs-timer font-size-35"></span></h1>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mt-4">
                                            <h5 class="font-size-14">Tiket Telah Di buat Menunggu Petugas Melakukan
                                                Pengerjaan</h5>
                                                {{ idTicket.isCustomer }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="stage.stageData.code === 'ticketSubmitted' && idTicket.category === 'installation' && idTicket.isCustomer == false">
                                    <div class="row">


                                        <div class="col-md-1">
                                            <div class="mt-3">
                                                <div class="row">
                                                    <h1><span class="bx bxs-timer font-size-30"></span></h1>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mt-4">
                                            <h5 class="font-size-13">Tiket Instalasi Telah Di Buat Menunggu Petugas
                                                Melakukan Pengerjaan Pada <span class="text-info font-size-13 me-1">{{
                                                    idTicket.deadlineInstallation }}</span></h5>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="stage.stageData.code === 'ticketSubmitted' && idTicket.category !== 'installation' && idTicket.isCustomer == true ">
                                    <div class="row">


                                        <div class="col-md-1">
                                            <div class="mt-3">
                                                <div class="row">
                                                    <h1><span class="bx bxs-timer font-size-30"></span></h1>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-11 mt-4">
                                            <h5 class="font-size-13">Tiket Dibuat Oleh Pelanggan Silahkan Melakukan Pengecekan Awal Terlebih Dahulu </h5>
                                            <div class="text-muted " v-if="stage.stageDetail === null">
                                            <b-button @click="checkTroubleShoot(stage, index)" variant="warning" pill size="sm"
                                                class="me-2"> Re-Check Device</b-button>
                                            <span><span class="badge bg-danger-subtle text-danger">Klik Untuk Memeriksa
                                                    Jaringan Pelanggan</span></span>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>

                            </div>

                            <div class="mt-0 mt-xl-0 ps-xl-4"
                                v-if="stage.stageData.code == 'ticketOnProgress' && stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == false">
                                <h5 class="font-size-14"><a href="#" class="text-muted">{{ stage.stageData.createdAt }} ,
                                        <span class="badge bg-info-subtle text-info">{{ stage.stageData.createdBy
                                        }}</span></a>
                                </h5>
                                <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                                    v-if="stage.stageData.isReadStage == 0"><i class="font-size-13">Baru</i></span>

                                <h4 class="font-size-18 mb-3" v-if="stage.stageData.code === 'ticketOnProgress'">Tiket Dalam
                                    Pengerjaan
                                </h4>
                                <div class="text-muted">
                                    <span class="badge bg-warning font-size-13 me-1">
                                        <div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>

                                            </div>
                                            <span class="mb-1 pl-2" style="padding-left: 0.63rem;"
                                                v-if="stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == false">Sedang
                                                Dalam Pengerjaan
                                            </span>
                                            <span class="mb-1 pl-2" style="padding-left: 0.63rem;"
                                                v-if="stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == true">Pengerjaan
                                                Diselesikan Pada
                                            </span>

                                        </div>


                                    </span>
                                    <span class="badge bg-info-subtle text-dark font-size-13 me-1">
                                        <div>{{ formattedDate(index, stage.stageData.createdAt) }}</div>

                                    </span>


                                </div>

                                <div class="mt-2"> Petugas Sedang Melakukan Perbaikan Silahkan Menunggu Update Berikutnya
                                </div>

                            </div>
                            <div class="mt-0 mt-xl-0 ps-xl-4"
                                v-if="stage.stageData.code == 'ticketOnProgress' && stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == true">
                                <h5 class="font-size-14"><a href="#" class="text-muted">{{ stage.stageData.createdAt }} ,
                                        <span class="badge bg-info-subtle text-info">{{ stage.stageData.createdBy
                                        }}</span></a>
                                </h5>
                                <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                                    v-if="stage.stageData.isReadStage == 0"><i class="font-size-13">Baru</i></span>

                                <h4 class="font-size-18 mb-3" v-if="stage.stageData.code === 'ticketOnProgress'">Tiket Dalam
                                    Pengerjaan
                                </h4>
                                <div class="text-muted">
                                    <span class="badge bg-success font-size-13 me-1">
                                        <!-- <div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div class="spinner-grow text-dark m-1 spinner-grow-sm blinking-spinner"
                                                role="status">
                                                <span class="sr-only">Loading...</span>

                                            </div>
                                            <span class="mb-1 pl-2" style="padding-left: 0.63rem;"
                                                v-if="stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == false">Sedang
                                                Dalam Pengerjaan
                                            </span>
                                            <span class="mb-1 pl-2" style="padding-left: 0.63rem;"
                                                v-if="stage.stageData.isOnProsesDone != null && stage.stageData.isOnProsesDone == true">Pengerjaan
                                                Selesai
                                            </span>

                                        </div> -->


                                    </span>



                                </div>

                                <div class="mt-2"> Petugas Telah Menyelesaikan Perbaikan Dengan <span
                                        class="badge bg-info-subtle text-dark font-size-13 me-1">
                                        <div>{{ formattedDate(index, stage.stageData.createdAt) }}</div>

                                    </span>
                                </div>

                            </div>


                            <div v-if="stage.stageData.code == 'ticketProgressDone'">
                                <div class="mt-0 mt-xl-0 ps-xl-4" v-if="stage.stageData.code === 'ticketProgressDone'">
                                    <h5 class="font-size-14"><a href="#" class="text-muted">{{ stage.stageData.createdAt }}
                                            ,
                                            <span class="badge bg-info-subtle text-info">{{ stage.stageData.createdBy
                                            }}</span></a>
                                    </h5>
                                    <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                                        v-if="stage.stageData.isReadStage == 0"><i class="font-size-13">Baru</i></span>

                                    <h4 class="font-size-18 mb-3" v-if="stage.stageData.code === 'ticketProgressDone'">
                                        Pengerjaan Tiket Selesai
                                    </h4>

                                    <div class="text-muted" v-if="stage.stageDetail != null">
                                        <span class="badge bg-success font-size-14 me-1"
                                            v-if="stage.stageDetail.rating >= 4"><i class="mdi mdi-star"></i> {{
                                                stage.stageDetail.rating }}
                                            Baik
                                        </span>
                                        <span class="badge bg-warning font-size-14 me-1"
                                            v-if="stage.stageDetail.rating <= 3"><i class="mdi mdi-star"></i>{{
                                                stage.stageDetail.rating }}
                                            Buruk
                                        </span>
                                        <span class="badge bg-danger font-size-14 me-1"
                                            v-if="stage.stageDetail.rating <= 2"><i class="mdi mdi-star"></i> {{
                                                stage.stageDetail.rating }}
                                            Sangat Buruk
                                        </span>

                                        <span v-for="(result) in stage.stageDetail.result" :key="result"><span
                                                :class="result === 'Tidak Ada Kendala' ? 'badge bg-success-subtle text-success' : 'badge bg-danger-subtle text-danger'">{{
                                                    result }}</span></span>
                                    </div>

                                    <div class="text-muted " v-if="stage.stageDetail === null">

                                        <b-button @click="checkTroubleShoot(stage, index)" variant="warning" pill size="sm"
                                            class="me-2"> Re-Check Device</b-button>

                                        <span><span class="badge bg-danger-subtle text-danger">Klik untuk Memeriksa
                                                Hasil</span></span>
                                    </div>


                                    <!-- <div class="text-muted " v-if="stateTroubleShoot.onuSignal != null">
                                        <span class="badge bg-success font-size-14 me-1"
                                            v-if="stateTroubleShoot.rating >= 4"><i class="mdi mdi-star"></i> {{
                                                stateTroubleShoot.rating }}
                                            Baik1
                                        </span>
                                        <span class="badge bg-warning font-size-14 me-1"
                                            v-if="stateTroubleShoot.rating <= 3"><i class="mdi mdi-star"></i>{{
                                                stateTroubleShoot.rating }}
                                            Buruk
                                        </span>
                                        <span class="badge bg-danger font-size-14 me-1"
                                            v-if="stateTroubleShoot.rating <= 2"><i class="mdi mdi-star"></i> {{
                                                stateTroubleShoot.rating }}
                                            Sangat Buruk
                                        </span>

                                        <span v-for="result in stateTroubleShoot.result" :key="result._id"
                                            style="margin-left:0.2rem;"><span
                                                :class="result === 'Tidak Ada Kendala' ? 'badge bg-success-subtle text-success' : 'badge bg-danger-subtle text-danger'">{{
                                                    result }}</span></span>
                                    </div> -->

                                    <div v-if="stage.stageDetail != null">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="mt-0">
                                                    <div class="row">
                                                        <h5 class="font-size-14 mt-2">Rekap Jaringan :</h5>
                                                        <div class="col-md-5">
                                                            <ul class="list-unstyled product-desc-list text-muted">
                                                                <li class="mb-1">
                                                                    <i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    oltRx
                                                                </li>
                                                                <li class="mb-1"><i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    onuRx</li>
                                                                <li class="mb-1"><i
                                                                        class="uil uil-clock-three text-primary me-1 font-size-16"></i>
                                                                    onuUptime
                                                                </li>
                                                                <li class="mb-1"><i
                                                                        class="uil uil-wifi-router text-primary me-1 font-size-16"></i>
                                                                    onuIP
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <b-input class="mb-1" size="sm" :value="stage.stageDetail.oltRx"
                                                                disabled></b-input>
                                                            <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuRx"
                                                                disabled></b-input>
                                                            <b-input class="mb-1" size="sm"
                                                                :value="stage.stageDetail.onuUptime" disabled></b-input>
                                                            <b-input class="mb-1" size="sm" :value="stage.stageDetail.onuIP"
                                                                disabled></b-input>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="mt-3">
                                                    <h5 class="font-size-14">Status Jaringan :</h5>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <ul class="list-unstyled product-desc-list text-muted">
                                                                <li><i
                                                                        class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                    onuState</li>
                                                                <li><i
                                                                        class="uil uil-signal text-primary me-1 font-size-16"></i>
                                                                    onuSignal</li>
                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <b-input class="mb-1" size="sm"
                                                                :value="stage.stageDetail.onuState" disabled></b-input>
                                                            <b-input class="mb-1" size="sm"
                                                                :value="stage.stageDetail.onuSignal" disabled></b-input>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <h5 class="font-size-14">Tagihan Bulan Ini :</h5>
                                                            <ul class="list-unstyled product-desc-list text-muted pt-1">
                                                                <li><i
                                                                        class="uil uil-bill text-primary me-1 font-size-16"></i>
                                                                    Status</li>

                                                            </ul>
                                                        </div>
                                                        <div class="col-md-5 mt-4"><b-input class="mt-1" size="sm"
                                                                :value="stage.stageDetail.checkingPayment"
                                                                disabled></b-input>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <transition name="fade">
                                                <div
                                                    v-if="stage.stageDetail.rating <= 3 && stage.stageDetail.rating != null">
                                                    <label> Hasil Pengecekan</label>
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <p><b-form-input
                                                                    value="Hasil pengerjaan tiket mendapat skor rendah, harap diperiksa kembali."
                                                                    disabled></b-form-input></p>
                                                        </div>

                                                        <div class="col-md-1 float-start" v-if="checkUpdateButton(index)">
                                                            <b-button variant="warning"
                                                                @click="rollBackUpdate">Update</b-button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </transition>
                                            <transition name="fade">
                                                <div
                                                    v-if="stage.stageDetail.rating > 3 && stage.stageDetail.rating != null">
                                                    <label> Hasil Pengecekan</label>
                                                    <div class="row">
                                                        <div class="col-md-10">
                                                            <p><b-form-input
                                                                    value="Hasil pengerjaan tiket baik, status tiket dapat diubah menjadi close"
                                                                    disabled></b-form-input></p>
                                                        </div>

                                                        <div class="col-md-1 float-start" v-if="checkUpdateButton(index)">
                                                            <b-button variant="success"
                                                                @click="rollBackUpdate">Update</b-button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </transition>
                                        </div>
                                    </div>

                                    <!-- <div v-if="stage.stageDetail == null">

                                        <div>
                                            <div class="d-flex justify-content-center align-items-center mt-3"
                                                style="height: 100%;">
                                                <div class="spinner-border text-primary m-1" role="status" v-if="isLoading">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </div>

                                            <transition name="fade">
                                                <div class="row" v-if="stateTroubleShoot.onuSignal != null">
                                                    <div class="col-md-6">
                                                        <div class="mt-3">
                                                            <div class="row">
                                                                <h5 class="font-size-14">Rekap Jaringan :</h5>
                                                                <div class="col-md-5">
                                                                    <ul class="list-unstyled product-desc-list text-muted">
                                                                        <li class="mb-1">
                                                                            <i
                                                                                class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                            oltRx
                                                                        </li>
                                                                        <li class="mb-1">
                                                                            <i
                                                                                class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                            onuRx
                                                                        </li>
                                                                        <li class="mb-1">
                                                                            <i
                                                                                class="uil uil-clock-three text-primary me-1 font-size-16"></i>
                                                                            onuUptime
                                                                        </li>
                                                                        <li class="mb-1">
                                                                            <i
                                                                                class="uil uil-wifi-router text-primary me-1 font-size-16"></i>
                                                                            onuIP
                                                                        </li>
                                                                    </ul>


                                                                </div>
                                                                <div class="col-md-5">
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.oltRx" disabled></b-input>
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.onuRx" disabled></b-input>
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.onuUptime"
                                                                        disabled></b-input>
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.onuIP" disabled></b-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mt-3">
                                                            <h5 class="font-size-14">Status Jaringan :</h5>
                                                            <div class="row">
                                                                <div class="col-md-5">
                                                                    <ul class="list-unstyled product-desc-list text-muted">
                                                                        <li>
                                                                            <i
                                                                                class="uil uil-exchange text-primary me-1 font-size-16"></i>
                                                                            onuState
                                                                        </li>
                                                                        <li>
                                                                            <i
                                                                                class="uil uil-signal text-primary me-1 font-size-16"></i>
                                                                            onuSignal
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.onuState"
                                                                        disabled></b-input>
                                                                    <b-input class="mb-1" size="sm"
                                                                        :value="stateTroubleShoot.onuSignal"
                                                                        disabled></b-input>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <h5 class="font-size-14">Tagihan Bulan Ini :</h5>
                                                                    <ul
                                                                        class="list-unstyled product-desc-list text-muted pt-1">
                                                                        <li>
                                                                            <i
                                                                                class="uil uil-bill text-primary me-1 font-size-16"></i>
                                                                            Status
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div class="col-md-5 mt-4">
                                                                    <b-input class="mt-1" size="sm"
                                                                        :value="stateTroubleShoot.checkingPayment"
                                                                        disabled></b-input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </transition>
                                            <transition name="fade">
                                                <div
                                                    v-if="stateTroubleShoot.rating <= 3 && stateTroubleShoot.rating != null">
                                                    <label> Hasil Pengecekan</label>
                                                    <div class="row">
                                                        <div class="col-md-9">
                                                            <p><b-form-input
                                                                    value="Hasil pengerjaan tiket mendapat skor rendah, harap diperiksa kembali."
                                                                    disabled></b-form-input></p>
                                                        </div>

                                                        <div class="col-md-1 float-start" v-if="checkUpdateButton(index)">
                                                            <b-button variant="warning"
                                                                @click="rollBackUpdate">Update</b-button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </transition>
                                            <transition name="fade">
                                                <div
                                                    v-if="stateTroubleShoot.rating > 3 && stateTroubleShoot.rating != null">
                                                    <label> Hasil Pengecekan</label>
                                                    <div class="row">
                                                        <div class="col-md-10">
                                                            <p><b-form-input
                                                                    value="Hasil pengerjaan tiket baik, status tiket dapat diubah menjadi close"
                                                                    disabled></b-form-input></p>
                                                        </div>

                                                        <div class="col-md-1 float-start" v-if="checkUpdateButton(index)">
                                                            <b-button variant="success"
                                                                @click="rollBackUpdate">Update</b-button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </transition>
                                        </div>

                                    </div> -->


                                </div>

                            </div>
                            <div v-if="stage.stageData.code == 'ticketDone'">
                                <div class="mt-0 mt-xl-0 ps-xl-4" v-if="stage.stageData.code === 'ticketDone'">
                                    <h5 class="font-size-14"><a href="#" class="text-muted">{{ stage.stageData.createdAt }}
                                            ,
                                            <span class="badge bg-info-subtle text-info">{{ stage.stageData.createdBy
                                            }}</span></a>
                                    </h5>
                                    <span class="float-end text-danger mdi mdi-bell-alert font-size-16"
                                        v-if="stage.stageData.isReadStage == 0"><i class="font-size-13">Baru</i></span>

                                    <h4 class="font-size-18 mb-3" v-if="stage.stageData.code === 'ticketDone'">
                                        Tiket Telah Selesai
                                    </h4>
                                    <h1 class="far fa-thumbs-up"></h1>
                                    <h5>
                                        <div class="font-size-15" v-if="dataPerbaikanFound == false"> Tiket Telah
                                            Terselesaikan , <span
                                                class="badge bg-warning-subtle text-danger font-size-13 me-1">Silahkan
                                                Lengkapi Form Data Perbaikan Dan Data Penggunaan Perangkat </span></div>
                                        <div class="font-size-15" v-if="dataPerbaikanFound == true"> Tiket Telah
                                            Terselesaikan ,<span
                                                class="badge bg-success-subtle text-primary font-size-13 me-1"> Terima Kasih
                                                Atas Kerja Sama nya </span></div>
                                    </h5>
                                </div>

                            </div>
                        </li>

                    </ol>

                </b-card>
            </div>

        </div>
        <b-modal id="modal-center" ref="modal-update-petugas" centered title="Update Petugas" title-class="font-18"
            size="sm">

            <div v-for="(item, index) in selectedPetugasCount" :key="selectedPetugasCount[index]" class="mt-2">
                <transition name="fade-in">
                    <div>
                        <b-form-select v-model="selectedPetugas[index]" :options="optionsPetugas" lazy
                            style="width:85%;height:70%;"></b-form-select>
                        <b-button size="sm" variant="danger"
                            style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                            class="ml-1" @click="updateAssigementSeleceted(index)"
                            v-if="index != selectedPetugasCount - 1"><i class="dripicons-minus"> </i>
                        </b-button>

                        <b-button size="sm" variant="success"
                            style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                            class="ml-1" @click="selectedPetugasCount++" v-if="index == selectedPetugasCount - 1"><i
                                class="dripicons-plus"> </i></b-button>
                    </div>
                </transition>

            </div>


            <template #modal-footer="{ cancel }">

                <!-- Emulate built in modal footer ok and cancel button actions -->

                <b-button size="md" variant="danger" class="float-start" @click="cancel()">
                    Cancel
                </b-button>
                <b-button size="md" variant="success" @click="updatePatchAssigemnt()">
                    Update
                </b-button>
            </template>
        </b-modal>

        <b-modal id="modal-center1" ref="modal-update-ticket" centered title="Update Status / Petugas Ticket"
            title-class="font-18" size="md">

            <div class="row">
                <div class="col-5">

                    <label> Status Ticket</label>
                    <p></p>
                    <label> Petugas</label>
                </div>
                <div class="col-7">
                    <b-form-select v-model="holderUpdateStatus" class="mb-3" style="width:100%">
                        <b-form-select-option :value="optionStatusUpdate.open">Open</b-form-select-option>
                        <b-form-select-option :value="optionStatusUpdate.closed">Closed</b-form-select-option>
                    </b-form-select>

                    <div v-for="(item, index) in selectedPetugasCount" :key="selectedPetugasCount[index]" class="mt-2">
                        <transition name="fade-in">
                            <div>
                                <b-form-select v-model="selectedPetugas[index]" :options="optionsPetugas" lazy
                                    style="width:87%;height:70%;"></b-form-select>
                                <b-button size="sm" variant="danger"
                                    style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                                    class="ml-1" @click="updateAssigementSeleceted(index)"
                                    v-if="index != selectedPetugasCount - 1"><i class="dripicons-minus"> </i>
                                </b-button>

                                <b-button size="sm" variant="success"
                                    style="height: 45%;margin-left: 0.313rem;border-bottom-width: 0px;border-top-width: 0px;border-left-width: 0px;border-right-width: 0px;"
                                    class="ml-1" @click="selectedPetugasCount++" v-if="index == selectedPetugasCount - 1"><i
                                        class="dripicons-plus"> </i></b-button>
                            </div>
                        </transition>

                    </div>
                </div>
            </div>




            <template #modal-footer="{ cancel }">

                <b-button size="md" variant="danger" class="float-start" @click="cancel()">
                    Cancel
                </b-button>
                <b-button size="md" variant="success" @click="updateTicketOnDetailPage()">
                    Update
                </b-button>
            </template>




        </b-modal>
    </Layout>
</template>
<style>
.spinner-grow-sm {
    width: 0.4rem;
    height: 0.4rem;
}

.spinner-border {
    width: 4rem;
    height: 4rem;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

}

.blinking-spinner:nth-child(1) {
    animation: blink 1s infinite;
    animation-delay: 0s;
}

.blinking-spinner:nth-child(2) {
    animation: blink 1s infinite;
    animation-delay: 0.33s;
}

.blinking-spinner:nth-child(3) {
    animation: blink 1s infinite;
    animation-delay: 0.66s;
}


.fade-enter-active,
.fade-leave-active {
    transition: opacity 2s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}
</style>